<template>
  <div>
    <v-checkbox
      v-model="userScopeConfig.canLoginToMainOrganization"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
      :label="$t('settings.users.hasAccessCaption')"
    />

    <v-checkbox
      v-model="userScopeConfig.isApplicationManager"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
      :label="$t('settings.users.isApplicationManager')"
    />

    <v-checkbox
      v-model="userScopeConfig.isAssignable"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
      :label="$t('settings.users.assignable')"
    />

    <v-checkbox
      v-model="userScopeConfig.canAssign"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
      :label="$t('settings.users.canSelectAllUsers')"
    />

    <v-checkbox
      v-model="userScopeConfig.hasBasicViewScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.basicView") }}</span>

        <v-tooltip>
          <template #activator="{ props: tooltipProps }">
            <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
          </template>

          <v-list density="compact" bg-color="transparent">
            <v-list-item
              v-for="scope in basicViewScopes"
              :key="scope"
              class="px-0"
              min-height="20px"
              :prepend-icon="generateScopeIcon(scope)"
            >
              {{ $t(`scope.${scope}`) }}
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="userScopeConfig.hasBasicEditScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.basicEdit") }}</span>

        <v-tooltip>
          <template #activator="{ props: tooltipProps }">
            <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
          </template>

          <v-list density="compact" bg-color="transparent">
            <v-list-item
              v-for="scope in basicEditScopes"
              :key="scope"
              class="px-0"
              min-height="20px"
              :prepend-icon="generateScopeIcon(scope)"
            >
              {{ $t(`scope.${scope}`) }}
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="userScopeConfig.hasAdvancedViewScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.advancedView") }}</span>

        <v-tooltip>
          <template #activator="{ props: tooltipProps }">
            <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
          </template>

          <v-list density="compact" bg-color="transparent">
            <v-list-item
              v-for="scope in advancedViewScopes"
              :key="scope"
              class="px-0"
              min-height="20px"
              :prepend-icon="generateScopeIcon(scope)"
            >
              {{ $t(`scope.${scope}`) }}
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="userScopeConfig.hasAdvancedEditScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.advancedEdit") }}</span>

        <v-tooltip>
          <template #activator="{ props: tooltipProps }">
            <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
          </template>

          <v-list density="compact" bg-color="transparent">
            <v-list-item
              v-for="scope in advancedEditScopes"
              :key="scope"
              class="px-0"
              min-height="20px"
              :prepend-icon="generateScopeIcon(scope)"
            >
              {{ $t(`scope.${scope}`) }}
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="userScopeConfig.hasBatchScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.batch") }}</span>

        <v-tooltip>
          <template #activator="{ props: tooltipProps }">
            <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
          </template>

          <v-list density="compact" bg-color="transparent">
            <v-list-item v-for="scope in batchScopes" :key="scope" class="px-0" min-height="20px" :prepend-icon="generateScopeIcon(scope)">
              {{ $t(`scope.${scope}`) }}
            </v-list-item>
          </v-list>
        </v-tooltip>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="userScopeConfig.hasStatusScopes"
      class="form__field"
      color="primary"
      hide-details
      density="comfortable"
      :readonly="readonly"
    >
      <template #label>
        <span>{{ $t("admin.users.authorization.statusEdit") }}</span>
      </template>
    </v-checkbox>
  </div>
</template>

<script setup lang="ts">
import { basicViewScopes, basicEditScopes, advancedViewScopes, advancedEditScopes, batchScopes } from "~~/stores/auth.store"

const userScopeConfig = defineModel<UserScopeConfig>({ required: true })

const props = defineProps<{ readonly?: boolean }>()
const { readonly } = toRefs(props)

const generateScopeIcon = (scope: string) => {
  if (scope.startsWith("CAN_VIEW")) {
    return "visibility"
  }
  if (scope.startsWith("CAN_UPDATE")) {
    return "edit"
  }
  if (scope.startsWith("CAN_CREATE")) {
    return "add"
  }
  if (scope.startsWith("CAN_DELETE")) {
    return "delete"
  }
  if (scope.startsWith("CAN_BATCH")) {
    return "list"
  }

  return "help"
}
</script>

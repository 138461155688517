<template>
  <v-form v-model="valid" :readonly="pending" @submit.prevent="() => onSubmit()">
    <div class="d-flex flex-wrap overflow-auto flex-grow-1">
      <div style="flex: 1; flex-basis: 360px">
        <v-card-text class="form-grid pt-0">
          <v-text-field
            v-model="user.email"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.email') + ' *'"
            :rules="[(val) => !!val || $t('validation.required'), (val) => /.+@.+/.test(val) || $t('validation.email')]"
          />

          <v-select
            v-model="user.userRole"
            :items="Object.values(UserRole)"
            class="form__field"
            clearable
            :label="$t('admin.users.field.userRole') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          >
            <template #selection="{ item }">
              <user-role-badge :role="item.raw" density="default" />
            </template>

            <template #item="{ item, props: itemProps }">
              <v-list-item v-bind="itemProps" title="">
                <user-role-badge :role="item.raw" />
              </v-list-item>
            </template>
          </v-select>

          <v-text-field
            v-model="user.firstName"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.firstName') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          />

          <v-text-field
            v-model="user.lastName"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.field.lastName') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          />

          <v-text-field v-model="user.phone" class="form__field" clearable :readonly="pending" :label="$t('admin.users.field.phone')" />

          <v-autocomplete
            v-model="user.organization"
            :items="organizations"
            item-title="description"
            item-value="_id"
            class="form__field"
            clearable
            :readonly="pending"
            :label="$t('admin.users.mainOrganization') + ' *'"
            :rules="[(val) => !!val || $t('validation.required')]"
          >
            <template #selection="{ item }">
              <v-icon class="mr-2" :icon="`$${item.raw.type.toLowerCase()}`" />
              <span>{{ item.title }}</span>
            </template>
            <template #item="{ item, props: itemProps }">
              <v-list-item v-bind="itemProps" :title="item.title" :prepend-icon="`$${item.raw.type.toLowerCase()}`">
                <template #append>
                  <v-icon class="mr-2" :icon="`$domain_${item.raw.domain.toLowerCase()}`" />
                </template>
              </v-list-item>
            </template>
          </v-autocomplete>

          <v-checkbox v-model="user.hasAccess" class="form__field" color="primary" :readonly="pending">
            <template #label>
              <span>{{ $t("admin.users.field.hasAccess") }}</span>

              <v-tooltip width="400">
                <template #activator="{ props: tooltipProps }">
                  <v-icon v-bind="tooltipProps" class="ml-2" icon="info_outline" />
                </template>

                <span>{{ $t("admin.users.field.hasAccessCaption") }}</span>
              </v-tooltip>
            </template>
          </v-checkbox>
        </v-card-text>
      </div>

      <div v-if="!editingUser" style="flex: 1; flex-basis: 360px">
        <v-card-text class="form-grid pt-0">
          <user-scope-checkboxes v-model="userScopeConfig" />
        </v-card-text>
      </div>
    </div>

    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="onCancel">{{ $t("cancel") }}</v-btn>
      <v-btn color="primary" type="submit" :loading="pending">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-form>
</template>

<script setup lang="ts">
import { UserRole } from "~~/stores/user.store"
import cloneDeep from "lodash-es/cloneDeep"

const props = defineProps<{ userData?: User }>()
const { userData } = toRefs(props)

const user = ref<Partial<User>>(userData.value ? cloneDeep(userData.value) : { userRole: UserRole.USER })

const emit = defineEmits<{
  (event: "ok", value: User): void
  (event: "cancel"): void
}>()

const $i18n = useI18n()

const mainStore = useMainStore()
const userStore = useUserStore()
const organizationStore = useOrganizationStore()

const { data: organizations } = useLazyAsyncData("organizations", () => organizationStore.getAllOrganizations({ pagination: false }), {
  default: () => [] as Array<Organization>,
})

const userScopeConfig = ref<UserScopeConfig>({
  canLoginToMainOrganization: false,
  isApplicationManager: false,
  isAssignable: true,
  canAssign: true,
  hasBasicViewScopes: false,
  hasBasicEditScopes: false,
  hasAdvancedViewScopes: false,
  hasAdvancedEditScopes: false,
  hasBatchScopes: false,
  hasStatusScopes: false,
})

const valid = ref<boolean | null>(null)
const pending = ref(false)

const editingUser = computed(() => !!user.value._id)

const onSubmit = () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    pending.value = true

    if (editingUser.value) {
      const userToBeUpdated = await userStore.getUserById(user.value._id!)

      const formKeys = ["email", "userRole", "firstName", "lastName", "phone", "hasAccess", "organization"] as Array<keyof User>
      const modifiedProperties = formKeys.filter((key) => user.value[key] !== userToBeUpdated[key])

      const updatePayload = modifiedProperties.reduce((acc, key) => ({ ...acc, [key]: user.value[key] }), {} as Partial<User>)

      const updatedUser = await userStore.updateOne(user.value._id!, updatePayload).finally(() => (pending.value = false))
      emit("ok", updatedUser)
      return
    }

    const newUser = await userStore.createOne({
      email: user.value.email!,
      firstName: user.value.firstName!,
      hasAccess: user.value.hasAccess ?? false,
      lastName: user.value.lastName!,
      organization: user.value.organization!,
      phone: user.value.phone,
      userRole: user.value.userRole!,
    })

    await setInitialUserScopes(newUser, userScopeConfig.value)

    emit("ok", newUser)
  }).finally(() => (pending.value = false))
}

const onCancel = () => {
  emit("cancel")
}
</script>
